<template>
  <div>
    <span
      class="size-status"
      style="color: green"
      v-if="ordinaria"
    >
      <v-icon
        small
        color="green"
        class="pb-1 icon-status"
      >
        mdi-thumb-up-outline
      </v-icon>
      Ordinária
    </span>
    <span
      class="size-status"
      style="color: red"
      v-else
    >
      <v-icon
        small
        color="red"
        class="pb-1 icon-status"
      >
        mdi-thumb-down-outline
      </v-icon>
      Não ordinária
    </span>
  </div>
</template>

<script>
export default {
  name: 'BdgdOrdinaria',
  props: {
    ordinaria: {
      type: Boolean,
      required: true,
      default: false
    }
  }
};
</script>

<style>
.size-status {
  font-size: 0.95rem !important;
  font-weight: normal !important;
}

.size-status .v-icon {
  font-size: 0.9rem !important;
}

.icon-status {
  margin-right: 1.7px !important;
}
</style>
